<template lang="pug">
#video
  video.player.video-js(ref="player", preload="metadata", controls, autoplay, playsinline="true", webkit-playsinline="true")
</template>

<script>
export default {
  name: "Video",
  components: {},
  data() {
    return {
      timer: null,
      videoUrl: "",
      player: null,
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    let trackName = this.$route.params.id;

    if (urlParams.has("t")) {
      this.videoUrl = `acopy${this.$route.params.id}_${urlParams.get("t")}.mp4`;
      trackName = `${this.$route.params.id}#${urlParams.get("t")}`;
    } else {
      this.videoUrl = `acopy${this.$route.params.id}.mp4`;
    }

    const id = parseInt(this.$route.params.id, 10);
    if (id > 14) {
      this.videoUrl = `https://cdn.google.36kr.com/videos/${this.videoUrl}`;
    } else {
      this.videoUrl = `https://google.36kr.com/static/${this.videoUrl}`;
    }

    this.$pushEvent("video", { video: this.$route.params.id });

    this.player = this.$video(this.$refs.player, {
      controls: true,
      autoplay: "play",
      preload: "auto",
      sources: [
        {
          src: this.videoUrl,
          type: "video/mp4",
        },
      ],
    });

    console.log(this.player);
    this.player.on("ended", () => {
      const id = parseInt(this.$route.params.id, 10);
      if (id < 13 || (id >= 15 && id < 52)) {
        const uri = `/videos/${id + 1}`;
        this.$pushEvent("openUri", { url: uri }).then(() => {
          window.location.assign(uri);
        });
      }
    });

    this.timer = setInterval(() => {
      this.$pushEvent("video_play", {
        video: trackName,
        time: this.player.currentTime(),
      });
    }, 10000);

    document.addEventListener(
      "WeixinJSBridgeReady",
      () => {
        this.player.play();
      },
      false
    );

    document.addEventListener(
      "touchstart",
      () => {
        setTimeout(() => {
          if (this.player.paused() && this.payer.currentTime() < 0.1) {
            this.player.play();
          }
        }, 1);
      },
      false
    );
  },
  destoyed() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="stylus" scoped>
#video
  min-height 100vh
  min-height -webkit-fill-available
  // Yet Another iOS bug
  background #000
.player
  width 100%
</style>
